import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import heroImage from '../assets/hero.jpg';
import '../index.css'; // Ensure this path is correct

const Hero = () => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    // Simulate a delay before showing the text
    const timer = setTimeout(() => {
      setShowText(true);
    }, 500); // Adjust delay time as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <section
      className="h-screen bg-cover bg-center flex items-center justify-center relative"
      style={{ backgroundImage: `url(${heroImage})` }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-75"></div>

      {/* Text Content with Fade-in Effect */}
      <div className="relative text-center text-white">
        <CSSTransition in={showText} timeout={500} classNames="fade" unmountOnExit>
          <div>
            <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-butler font-thin text-nice-brown">S S TAILOR LONDON</h2>
            <p className="mt-4 mx-3 text-base sm:text-lg md:text-xl italic">Experience the best bespoke tailoring and alterations</p>
            <Link to="/booking" className="mt-8 inline-block px-6 py-3 bg-deep-brown text-white font-semibold shadow-lg hover:bg-dark-nice-brown transition-colors">
              Book Now
            </Link>
            <p className="mt-4 mx-3 text-base sm:text-lg md:text-xl italic">
              Call Us Now:&nbsp;
              <a href="tel:07440114476" className="text-blue-500 hover:underline">
                07440 114476
              </a>
            </p>
          </div>
        </CSSTransition>
      </div>
    </section>
  );
}

export default Hero;
