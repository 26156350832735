import React from 'react';
import { Link } from 'react-router-dom';
import service1 from '../assets/service1.jpg';
import service2 from '../assets/service2.jpg';
import service3 from '../assets/service3.jpg';

const Services = () => {
  return (
    <section className="py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-thin text-deep-brown font-butler mb-8">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          
          {/* Service 1 */}
          <Link to="/bespoke-tailoring" className="relative block">
            <img src={service1} alt="Service 1" className="rounded-full w-40 h-40 mx-auto mb-4 object-cover" />
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Bespoke Tailoring</h3>
              <p>Get perfectly tailored suits and dresses made just for you.</p>
            </div>
          </Link>

          {/* Service 2 */}
          <Link to="/alterations" className="relative block">
            <img src={service2} alt="Service 2" className="rounded-full w-40 h-40 mx-auto mb-4 object-cover" />
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Alterations</h3>
              <p>We provide alteration services for suit, wedding dresses, jeans, or casual clothing.</p>
            </div>
          </Link>

          {/* Service 3 */}
          <Link to="/repairs" className="relative block">
            <img src={service3} alt="Service 3" className="rounded-full w-40 h-40 mx-auto mb-4 object-cover" />
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Repairs</h3>
              <p>Our experts can repair your favorite clothes to make them like new.</p>
            </div>
          </Link>

        </div>
        <div className="border-b border-deep-brown border-solid mt-8"></div>
      </div>
    </section>
  );
}

export default Services;
