import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import RepairsImg from '../assets/RepairsImg.jpeg';

const Repairs = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <header className="bg-deep-brown text-white py-4 text-center">
        <h1 className="text-4xl font-semibold">Clothing Repairs</h1>
        <Link to="/" className="underline text-white mt-2 block">Back to Home</Link>
      </header>

      {/* Main Content with Animation */}
      <main className="flex-grow flex flex-col lg:flex-row items-center justify-center px-6 py-12 lg:px-16 lg:py-24 gap-12">
        
        {/* Text Content with Fade-in Animation */}
        <motion.div 
          className="max-w-2xl text-left"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <h2 className="text-4xl font-semibold text-gray-800 mb-6 font-serif">Professional Clothing Repairs in Canary Wharf</h2>
          <p className="text-lg text-gray-700 leading-relaxed font-light">
            Do you have a favorite piece of clothing that needs repairing? At <span className="font-semibold">S S Tailor London</span>, we 
            specialize in high-quality clothing repairs to restore your garments to their original condition. 
            From minor fixes to extensive restorations, our expert tailors handle every repair with precision and care.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mt-4 font-light">
            Whether it's a torn seam, damaged zipper, or worn-out fabric, our team is dedicated to providing 
            exceptional repair services. We understand how important your wardrobe is, and we ensure that every 
            repair blends seamlessly with the original craftsmanship.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mt-4 font-light">
            With years of experience, we offer quick and efficient turnaround times without compromising on quality. 
            Bring in your garments today and let our skilled tailors bring them back to life.
          </p>
          <Link 
            to="/booking" 
            className="inline-block mt-6 px-6 py-3 text-white bg-deep-brown rounded-lg shadow-md text-lg hover:bg-opacity-90 transition duration-300"
          >
            Book a Repair Consultation
          </Link>
        </motion.div>

        {/* Image Section with Fade-in Animation */}
        <motion.div 
          className="max-w-md"
          initial={{ opacity: 0, x: 30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: "easeOut", delay: 0.2 }}
        >
          <img 
            src={RepairsImg} 
            alt="Clothing Repairs Service" 
            className="rounded-lg shadow-lg object-cover w-full h-auto"
          />
        </motion.div>
      </main>

      {/* Footer */}
      <footer className="bg-deep-brown text-white py-4 text-center">
        <Link to="/" className="underline">Back to Home</Link>
      </footer>
    </div>
  );
};

export default Repairs;
