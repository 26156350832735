import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import AlterationsImg from '../assets/AlterationsImg.jpeg';

const Alterations = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <header className="bg-deep-brown text-white py-4 text-center">
        <h1 className="text-4xl font-semibold">Alterations</h1>
        <Link to="/" className="underline text-white mt-2 block">Back to Home</Link>
      </header>

      {/* Main Content with Animation */}
      <main className="flex-grow flex flex-col lg:flex-row items-center justify-center px-6 py-12 lg:px-16 lg:py-24 gap-12">
        
        {/* Text Content with Fade-in Animation */}
        <motion.div 
          className="max-w-2xl text-left"
          initial={{ opacity: 0, y: 30 }} // Start invisible and slightly down
          animate={{ opacity: 1, y: 0 }} // Fade in and move to normal position
          transition={{ duration: 1, ease: "easeOut" }} // Smooth effect
        >
          <h2 className="text-4xl font-bold text-gray-800 mb-6 font-serif">Alterations in Canary Wharf</h2>
          <p className="text-lg text-gray-700 leading-relaxed font-light">
            Are you in need of professional alterations in Canary Wharf? Look no further than 
            <span className="font-semibold"> S S Tailor London</span>. With years of experience, we are a trusted and reliable tailor 
            providing exceptional alterations services that meet your needs.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mt-4 font-light">
            At S S Tailor London, we understand the importance of looking your best. Whether it’s a 
            <span className="font-semibold"> suit, dress, or any other garment</span>, our skilled team is dedicated to delivering 
            high-quality alterations that ensure a perfect fit. We take pride in our craftsmanship, leaving you with a 
            garment that feels like it was made just for you.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mt-4 font-light">
            Our Alterations service includes a wide range of options, such as 
            <span className="font-semibold"> hemming, resizing, and restyling</span>. No matter how complex the alteration, 
            our expert tailors deliver outstanding results with precision and care.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mt-4 font-light">
            We know how valuable your time is, which is why we provide 
            <span className="font-semibold"> quick turnaround times</span> without compromising on quality. Our efficient process 
            ensures your altered garment is ready when you need it.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mt-4 font-light">
            Choosing <span className="font-semibold"> S S Tailor London</span> means trusting professionals who prioritize 
            customer satisfaction. We are committed to delivering exceptional service that exceeds your expectations.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mt-4 font-light">
            Experience the difference of our top-notch alterations services in Canary Wharf today. Visit our website 
            for more information or contact us directly to schedule an appointment. Let our expert tailors help you 
            <span className="font-semibold"> look and feel your best</span> with our high-quality alterations.
          </p>
          <Link 
            to="/booking" 
            className="inline-block mt-6 px-6 py-3 text-white bg-deep-brown rounded-lg shadow-md text-lg hover:bg-opacity-90 transition duration-300"
          >
            Book an Appointment
          </Link>
        </motion.div>

        {/* Image Section with Fade-in Animation */}
        <motion.div 
          className="max-w-md"
          initial={{ opacity: 0, x: 30 }} // Start invisible and slightly to the right
          animate={{ opacity: 1, x: 0 }} // Fade in and move to normal position
          transition={{ duration: 1, ease: "easeOut", delay: 0.2 }} // Slight delay for better effect
        >
          <img 
            src={AlterationsImg} 
            alt="Alterations Service" 
            className="rounded-lg shadow-lg object-cover w-full h-auto"
          />
        </motion.div>

      </main>

      {/* Footer */}
      <footer className="bg-deep-brown text-white py-4 text-center">
        <Link to="/" className="underline">Back to Home</Link>
      </footer>
    </div>
  );
};

export default Alterations;
