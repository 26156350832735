import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import TailoringImg from '../assets/TailoringImg.jpeg';

const BespokeTailoring = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <header className="bg-deep-brown text-white py-4 text-center">
        <h1 className="text-4xl font-semibold">Bespoke Tailoring</h1>
        <Link to="/" className="underline text-white mt-2 block">Back to Home</Link>
      </header>

      {/* Main Content with Animation */}
      <main className="flex-grow flex flex-col lg:flex-row items-center justify-center px-6 py-12 lg:px-16 lg:py-24 gap-12">
        
        {/* Text Content with Fade-in Animation */}
        <motion.div 
          className="max-w-2xl text-left"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <h2 className="text-4xl font-semibold text-gray-800 mb-6 font-serif">Tailor In Canary Wharf</h2>
          <p className="text-lg text-gray-700 leading-relaxed font-light">
            Looking for a professional tailor in Canary Wharf? Look no further than 
            <span className="font-semibold"> S S Tailor London</span>. With years of experience and a reputation for excellence, 
            we are the go-to destination for all your tailoring needs. Whether you need a suit tailored for a special occasion or 
            alterations to your favorite garments, our skilled tailors are here to help.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mt-4 font-light">
            At S S Tailor London, we take the time to listen to your needs and preferences, ensuring every garment is tailored to 
            perfection. We use the finest materials and techniques to create garments that showcase your individual style.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mt-4 font-light">
            Whether you need a <span className="font-semibold">tailored suit for a corporate event</span> or <span className="font-semibold">alterations for your everyday wardrobe</span>, we have you covered. 
            We are committed to exceeding your expectations with every garment we create.
          </p>
          <Link 
            to="/booking" 
            className="inline-block mt-6 px-6 py-3 text-white bg-deep-brown rounded-lg shadow-md text-lg hover:bg-opacity-90 transition duration-300"
          >
            Book an Appointment
          </Link>

          <h2 className="text-4xl font-semibold text-gray-800 mt-12 mb-6 font-serif">Suit Tailor In Canary Wharf</h2>
          <p className="text-lg text-gray-700 leading-relaxed font-light">
            Looking for a professional <span className="font-semibold">suit tailor</span> in Canary Wharf? Look no further than 
            <span className="font-semibold"> S S Tailor London</span>. With years of experience and a commitment to excellence, 
            we are the go-to choice for all your suit tailoring needs.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mt-4 font-light">
            Whether you need a tailored suit for a special occasion or want to upgrade your professional wardrobe, 
            our expert tailors ensure that <span className="font-semibold">every stitch is precise and every garment is made to perfection</span>.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mt-4 font-light">
            We also offer <span className="font-semibold">personalized consultations</span> to help you choose the best style, fit, and material. 
            Our goal is to make sure you walk away with a suit that makes you feel confident.
          </p>
        </motion.div>

        {/* Image Section with Fade-in Animation */}
        <motion.div 
          className="max-w-md"
          initial={{ opacity: 0, x: 30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: "easeOut", delay: 0.2 }}
        >
          <img 
            src={TailoringImg} 
            alt="Bespoke Tailoring Service" 
            className="rounded-lg shadow-lg object-cover w-full h-auto"
          />
        </motion.div>
      </main>

      {/* Footer */}
      <footer className="bg-deep-brown text-white py-4 text-center">
        <Link to="/" className="underline">Back to Home</Link>
      </footer>
    </div>
  );
};

export default BespokeTailoring;
